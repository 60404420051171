<template>
  <div>
    <div class="mb-3">
      <Breadcrumbs :items="items" />
    </div>
    <tablaComponent
      ref="tabla"
      :headers="headers"
      :titulo-tabla="tituloTabla"
      :url="url"
      :cargando="cargando"
      :have-actions="haveActions"
      :have-add="haveAdd"
      :have-edit="haveEdit"
      :have-delete="haveDelete"
      :search="search"
      :titulo-search="tituloSearch"
      @nuevoform="abrirForm()"
      @editform="editItem($event)"
      @deleteitem="deleteItem($event)"
      @dialogSearch="cargarSearch()"
      @refreshSearch="refreshSearch()"
    >
      <template v-slot:filterSearch>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="filterFilesSelected"
              :items="filterFiles"
              item-value="id"
              item-text="name"
              outlined
              dense
              chips
              small-chips
              label="Filtrar por"
              multiple
              hide-details
            >
              <template v-slot:selection="data">
                <v-chip
                  class="elevation-2 my-1"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeFilterFilesSelected(data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- Name -->
          <v-col
            v-if="isNameSelected"
            cols="12"
          >
            <v-text-field
              v-model="nameSearch"
              label="Nombre"
              outlined
              dense
              placeholder="Buscar por nombre"
              hide-details
            ></v-text-field>
          </v-col>
          <!-- Description -->
          <v-col
            v-if="isDescriptionSelected"
            cols="12"
          >
            <v-text-field
              v-model="descriptionSearch"
              label="Descripción"
              outlined
              dense
              placeholder="Buscar por descripción"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <!-- <template v-slot:item.profilePermissions="{ item } ">
        <span v-for="j of item.profilePermissions" :key="j">
          <v-chip
            small
            class="mr-1 ml-1 mt-1 elevation-2"
            color="blue lighten-5"
          >
            {{ j }}
          </v-chip>
        </span>
      </template> -->
    </tablaComponent>
    <!-- Formulario de Confirmación -->
    <confirm ref="confirm"></confirm>
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { computed, ref, reactive } from '@vue/composition-api'
import HTTP from '@/utils/axios/axios-config-base'
import { useRouter } from '@/utils'
import tablaComponent from '@/components/tablaComponent.vue'
import Confirm from '@/components/Confirm.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  created() {
    if (this.$route.params.notificaciones != null) {
      this.notificaciones.snackbar = this.$route.params.notificaciones.snackbar
      this.notificaciones.text = this.$route.params.notificaciones.text
      this.notificaciones.color = this.$route.params.notificaciones.color
    }
  },
  components: { tablaComponent, Confirm, Breadcrumbs },
  setup() {
    const { router } = useRouter()
    const tituloTabla = ref('Perfiles')
    const url = ref(`${HTTP.defaults.baseURL}identity-api/Profile`)
    const confirm = ref(null)
    const cargando = ref(false)
    const haveActions = ref(true)

    // const haveEdit = ref(true)
    // const haveDelete = ref(true)
    const headers = ref([
      { text: 'Nombre', sortable: false, value: 'name' },
      { text: 'Descripción', sortable: false, value: 'description' },

      // { text: 'Permisos', sortable: false, value: 'profilePermissions' },
    ])
    const tabla = ref(null)
    const search = ref({})
    const tituloSearch = ref('Buscar Perfiles')
    const filterFiles = [
      { id: 'name', name: 'Nombre' },
      { id: 'description', name: 'Descripción' },
    ]
    const filterFilesSelected = ref([])
    const isNameSelected = computed(() => filterFilesSelected.value.includes('name'))
    const nameSearch = ref('')
    const isDescriptionSelected = computed(() => filterFilesSelected.value.includes('description'))
    const descriptionSearch = ref('')
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })
    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: `${ tituloTabla.value }`,
        disabled: true,
        href: '/profile',
      },
    ])

    // para cargar los permisos y mostrar o no los botones de accion de la tabla
    const permissions = localStorage.getItem('permissions')
    const haveDelete = computed(() => permissions.includes('Profiles:Profiles:FullAccess') || permissions.includes('Profiles:Profiles:Delete'))
    const haveEdit = computed(() => permissions.includes('Profiles:Profiles:FullAccess') || permissions.includes('Profiles:Profiles:ReadEditAccess'))
    const haveAdd = computed(() => permissions.includes('Profiles:Profiles:FullAccess') || permissions.includes('Profiles:Profiles:CreateAccess'))

    return {
      headers,
      router,
      tituloTabla,
      url,
      cargando,
      haveActions,
      haveAdd,
      haveEdit,
      haveDelete,
      confirm,
      tabla,
      search,
      items,
      tituloSearch,
      filterFiles,
      filterFilesSelected,
      isNameSelected,
      nameSearch,
      isDescriptionSelected,
      descriptionSearch,
      notificaciones,
      cargarSearch: () => {
        const searchAll = {}
        if (filterFilesSelected.value.includes('name')) {
          if (nameSearch.value !== '') {
            searchAll.Name = nameSearch.value
          }
        }
        if (filterFilesSelected.value.includes('description')) {
          if (descriptionSearch.value !== '') {
            searchAll.Description = descriptionSearch.value
          }
        }
        search.value = searchAll
      },
      refreshSearch: () => {
        filterFilesSelected.value = []
        search.value = {}
      },
      removeFilterFilesSelected: async item => {
        const index = filterFilesSelected.value.indexOf(item.id)
        if (index >= 0) filterFilesSelected.value.splice(index, 1)
      },
      abrirForm: () => {
        cargando.value = true
        setTimeout(() => {
          router.push(
            {
              name: 'gestion_profile_form',
              params: {
                obj: null,
              },
            },
          )
          cargando.value = false
        }, 500)
      },
      editItem: item => {
        cargando.value = true
        setTimeout(() => {
          const items = [
            {
              text: 'Inicio',
              disabled: false,
              href: '/',
            },
            {
              text: 'Perfiles',
              disabled: false,
              href: '/profile',
            },
            {
              text: 'Editar perfil',
              disabled: true,
              href: '/profile/form',
            },
          ]
          router.push(
            {
              name: 'gestion_profile_form',
              params: {
                obj: item,
                formTitulo: 'Editar perfil',
                items,
              },
            },
          )
          cargando.value = false
        }, 500)
      },
      deleteItem: async item => {
        confirm.value
          .open(
            'Eliminar perfil',
            '¿Está seguro que desea eliminar el perfil seleccionado?',
            {
              color: 'primary',
              width: '450',
            },
          )
          .then(confirm => {
            if (confirm) {
              const URL = `${HTTP.defaults.baseURL}identity-api/profile/${item.id}`

              const resp = HTTP.delete(URL)
              resp.then(val => {
                if (val.status === 200) {
                  notificaciones.color = 'success'
                  notificaciones.text = 'El registro se ha eliminado satisfactoriamente'
                  notificaciones.snackbar = true
                  tabla.value.listar()
                } else {
                  notificaciones.color = 'error'
                  notificaciones.text = 'Ha ocurrido un error mientras se eliminaba el registro'
                  notificaciones.snackbar = true
                }
              })
            } else {
              notificaciones.color = 'info'
              notificaciones.text = 'Operación cancelada'
              notificaciones.snackbar = true
            }
          })
      },
    }
  },
}
</script>

<style>
  .truncate {
    max-width: 20vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
